img.img-error {
    max-width: 540px;
}

.alert.alert-purple {
    margin-top: 40px;
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #6246EA;
}

.message {
    margin-top: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #595959;
}
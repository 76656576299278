.changeUserPasswordModal {
}

    .changeUserPasswordModal .changeUserPasswordModal-header {
        background: #F8F8FB;
        padding: 80px 30px 15px 30px;
        text-align: center;
    }

    .changeUserPasswordModal .changeUserPasswordModal-header h2 {
        margin: 20px 0 0 0;
        font-size: 24px;
        line-height: 1.2;
        color: #595959;
    }

    .changeUserPasswordModal .changeUserPasswordModal-body {
        background: #F8F8FB;
        padding: 15px 90px 30px 90px;
    }

    @media (max-width: 575px) {
        .changeUserPasswordModal .changeUserPasswordModal-header {
            padding: 15px 15px 15px 15px;
        }

        .changeUserPasswordModal .changeUserPasswordModal-body {
            padding: 15px 15px 15px 15px;
        }
    }

        .changeUserPasswordModal .changeUserPasswordModal-body .password-rules {
            margin: 4px 0 0 0;
        }

            .changeUserPasswordModal .changeUserPasswordModal-body .password-rules .rule {
                display: inline-block;
                width: 48%;
                margin: 0 0 4px 0;
                font-size: 12px;
            }

            @media (max-width: 575px) {
                .changeUserPasswordModal .changeUserPasswordModal-body .password-rules .rule {
                    display: block;
                    width: 100%;
                }
            }

                .changeUserPasswordModal .changeUserPasswordModal-body .password-rules .rule .box {
                    display: inline-block;
                    background: #efefef;
                    border: 1px solid #D1D1E9;
                    width: 10px;
                    height: 10px;
                    margin: 0 4px 0 0;
                }

                .changeUserPasswordModal .changeUserPasswordModal-body .password-rules .rule.success .box {
                    background: #3f8e2b;
                }

                .changeUserPasswordModal .changeUserPasswordModal-body .password-rules .rule.danger .box {
                    background: #d5615d;
                }

    .changeUserPasswordModal-footer {
        background: #F8F8FB;
        padding: 15px 90px 90px 90px;
    }
.comandante-editor {
	background: transparent !important;
	font-family: 'Inter', sans-serif;
	font-size: 14px;
	line-height: 20px;
}

.comandante-editor.disabled {
	background: #F8F8FB !important;
}

.comandante-activities-editor {
	border-top: 1px solid #C0C0C3;
	box-sizing: border-box;
}

	.comandante-editor .RichEditor-editor {
		border-top: 0 none;
		margin: 10px 0 0 0;
		font-family: 'Inter', sans-serif;
		cursor: text;
	}

	.comandante-editor .RichEditor-editor .public-DraftEditorPlaceholder-root {
		margin: 0 0 0 0;
		padding: 0 16px 0 16px;
	}

	.comandante-editor .DraftEditor-root .public-DraftEditorPlaceholder-inner {
		margin: 0 0 0 2px;
		font-size: 14px;
		line-height: 25px;
		color: #C0C0C3;
	}

	.comandante-editor .RichEditor-editor .public-DraftEditor-content {
		border-bottom: 1px solid #DFE0E1;
		min-height: 240px;
		margin: 0 0 0 0;
		padding: 4px 16px 4px 16px;
		max-height: 240px;
		overflow: auto;
	}

	.comandante-editor.disabled .RichEditor-editor .public-DraftEditor-content:hover {
		cursor: not-allowed;
	}

	.comandante-editor.read-only .RichEditor-editor .public-DraftEditor-content {
		border: 0 none;
	}

	.comandante-editor.read-only .RichEditor-editor .public-DraftEditor-content,
	.comandante-activities-editor.read-only .RichEditor-editor .public-DraftEditor-content {
		min-height: unset;
	}

	.comandante-editor.comandante-activities-editor .RichEditor-editor .public-DraftEditor-content {
		border-bottom: 0 none;
		min-height: 60px;
	}

		.comandante-editor .RichEditor-editor .public-DraftEditor-content .RichEditor-default {
			font-size: 14px;
			line-height: 18px;
		}

		.comandante-editor .RichEditor-editor .public-DraftEditor-content .RichEditor-H1 {
			font-size: 24px;
			line-height: 32px;
		}

		.comandante-editor .RichEditor-editor .public-DraftEditor-content .RichEditor-H2 {
			font-size: 20px;
			line-height: 30px;
		}

		.comandante-editor .RichEditor-editor .public-DraftEditor-content .RichEditor-H3 {
			font-size: 18px;
			line-height: 26px;
		}
		
		.comandante-editor .RichEditor-editor .public-DraftEditor-content .RichEditor-red { color: #E45858; }
		.comandante-editor .RichEditor-editor .public-DraftEditor-content .RichEditor-yellow { color: #E4C558; }
		.comandante-editor .RichEditor-editor .public-DraftEditor-content .RichEditor-blue { color: #657ADD; }
		.comandante-editor .RichEditor-editor .public-DraftEditor-content .RichEditor-green { color: #00910E; }

	.comandante-editor .RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
		display: none;
	}

	.RichEditor-controls {
		margin-bottom: 5px;
		user-select: none;
	}

	.comandante-activities-editor .RichEditor-controls {
		line-height: 35px;
	}
  
	.comandante-editor .RichEditor-styleButton {
		display: inline-block;
		margin: 0 16px 4px 0;
		padding: 2px 0 2px 0;
		font-size: 16px;
		color: #C0C0C3;
		cursor: pointer;
	}

	.comandante-editor .RichEditor-styleButton.RichEditor-activeButton {
		color: #595959;
	}

	.comandante-editor .RichEditor-styleButton.RichEditor-activeButton svg path {
		fill: #595959;
	}

.mentioned-user {
	background: transparent;
	color: #6246EA !important;
}

div[mentionSuggestions="mentionSuggestions"] {
	overflow-y: scroll;
	max-height: 195px;
}

	.suggestion-W {

	}

		.suggestion-W .suggestions-C1 {
			margin: 0 8px 0 0;
		}

			.suggestion-W .suggestions-C1 .avatar {
				border-radius: 100px;
				width: 24px;
				height: 24px;
				margin: 4px 0 0 0;
			}
			
			.suggestion-W .suggestions-C2 {
				margin: 0 0 0 8px;
			}

				.suggestion-W .suggestions-C2 .name {
					font-weight: 500;
					font-size: 13px;
					line-height: 18px;
				}

				.suggestion-W .suggestions-C2 .username {
					font-weight: 400;
					font-size: 11px;
					line-height: 15px;
				}
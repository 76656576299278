@import '~antd/dist/antd.less';

/* Hover */
.hover-pointer:hover {
    cursor: pointer;
}

/* Heading */
h1, h2, h3, h4, h5, h6 {
    margin: 0 0 0 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #595959;
}

/* Width & Height */
.w-100 { width: 100% !important; }
.w-75 { width: 75% !important; }
.w-50 { width: 50% !important; }
.w-25 { width: 25% !important; }

.max-w-100 { max-width: 100% !important; }
.max-w-75 { max-width: 75% !important; }
.max-w-50 { max-width: 50% !important; }
.max-w-25 { max-width: 25% !important; }

/* Aligner */
.aligner-x {
    display: flex;
    justify-content: center;
}

.aligner-y {
    display: flex;
    align-items: center;
}

.aligner-xy {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-center { text-align: center; }
.text-left { text-align: left; }
.text-right { text-align: right; }

/* Margin */
.m-0 { margin: 0 0 0 0 !important; }
.m-1 { margin: 4px 4px 4px 4px !important; }
.m-2 { margin: 8px 8px 8px 8px !important; }
.m-3 { margin: 12px 12px 12px 12px !important; }
.m-4 { margin: 16px 16px 16px 16px !important; }
.m-5 { margin: 20px 20px 20px 20px !important; }
@media (min-width: 1200px) { .m-xl-5 { margin: 20px 20px 20px 20px !important; } }

.mt-0 { margin-top: 0 !important; }
.mt-1 { margin-top: 4px !important; }
.mt-2 { margin-top: 8px !important; }
.mt-3 { margin-top: 12px !important; }
.mt-4 { margin-top: 16px !important; }
.mt-5 { margin-top: 20px !important; }

.mr-0 { margin-right: 0 !important; }
.mr-1 { margin-right: 4px !important; }
.mr-2 { margin-right: 8px !important; }
.mr-3 { margin-right: 12px !important; }
.mr-4 { margin-right: 16px !important; }
.mr-5 { margin-right: 20px !important; }

.mb-0 { margin-bottom: 0 !important; }
.mb-1 { margin-bottom: 4px !important; }
.mb-2 { margin-bottom: 8px !important; }
.mb-3 { margin-bottom: 12px !important; }
.mb-4 { margin-bottom: 16px !important; }
.mb-5 { margin-bottom: 20px !important; }

.ml-0 { margin-left: 0 !important; }
.ml-1 { margin-left: 4px !important; }
.ml-2 { margin-left: 8px !important; }
.ml-3 { margin-left: 12px !important; }
.ml-4 { margin-left: 16px !important; }
.ml-5 { margin-left: 20px !important; }

/* Padding */
.p-0 { padding: 0 0 0 0 !important; }
.p-1 { padding: 4px 4px 4px 4px !important; }
.p-2 { padding: 8px 8px 8px 8px !important; }
.p-3 { padding: 12px 12px 12px 12px !important; }
.p-4 { padding: 16px 16px 16px 16px !important; }
.p-5 { padding: 20px 20px 20px 20px !important; }

.pt-0 { padding-top: 0 !important; }
.pt-1 { padding-top: 4px !important; }
.pt-2 { padding-top: 8px !important; }
.pt-3 { padding-top: 12px !important; }
.pt-4 { padding-top: 16px !important; }
.pt-5 { padding-top: 20px !important; }

.pr-0 { padding-right: 0 !important; }
.pr-1 { padding-right: 4px !important; }
.pr-2 { padding-right: 8px !important; }
.pr-3 { padding-right: 12px !important; }
.pr-4 { padding-right: 16px !important; }
.pr-5 { padding-right: 20px !important; }

.pb-0 { padding-bottom: 0 !important; }
.pb-1 { padding-bottom: 4px !important; }
.pb-2 { padding-bottom: 8px !important; }
.pb-3 { padding-bottom: 12px !important; }
.pb-4 { padding-bottom: 16px !important; }
.pb-5 { padding-bottom: 20px !important; }

.pl-0 { padding-left: 0 !important; }
.pl-1 { padding-left: 4px !important; }
.pl-2 { padding-left: 8px !important; }
.pl-3 { padding-left: 12px !important; }
.pl-4 { padding-left: 16px !important; }
.pl-5 { padding-left: 20px !important; }

/* Float */
.float-left { float: left; }
.float-right { float: right; }
.clearfix::after { content: ""; clear: both; display: table; }

/* Display */
.d-none { display: none; }
.d-inline { display: inline; }
.d-inline-block { display: inline-block; }
.d-flex { display: flex; }
.d-block { display: block; }

@media (max-width: 575px) { .d-xs-none { display: none !important; } }
@media (min-width: 576px) and (max-width: 767px) { .d-sm-none { display: none !important; } }
@media (min-width: 768px) and (max-width: 991px) { .d-md-none { display: none !important; } }
@media (min-width: 992px) and (max-width: 1199px) { .d-lg-none { display: none !important; } }
@media (min-width: 1200px) { .d-xl-none { display: none !important; } }

/* Icon */
img.icon { height: 11px; }

/* Form */
.ant-form-vertical {}

    .ant-form-vertical .ant-form-item {
        margin: 16px 0 0 0;
    }

        .ant-form-vertical .ant-form-item .ant-form-item-label {
            margin: 0 0 0px 0;
            padding: 0 0 0 0;
        }

            .ant-form-vertical .ant-form-item .ant-form-item-label label {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #8C8C8C;
            }

        .ant-form-vertical .ant-form-item .ant-form-item-control {
            margin: 0 0 0 0;
        }

    .svg-select-box .ant-select-selection-item {
        padding: 2px 0 0 0 !important;
    }

.ant-form-horizontal {}

    .ant-form-horizontal .ant-form-item {
        margin: 16px 0 0 0 !important;
    }

        .ant-form-horizontal .ant-form-item .ant-form-item-label {
            margin: 0 0 0px 0;
            padding: 0 0 0 0 !important;
            height: 40px;
            line-height: 40px;
            text-align: left;
        }

            .ant-form-horizontal .ant-form-item .ant-form-item-label label {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #8C8C8C;
            }
        
        .ant-form-horizontal .ant-form-item input,
        .ant-form-horizontal .ant-form-item textarea,
        .ant-form-horizontal .ant-form-item .ant-select-selector,
        .ant-form-horizontal .ant-input-affix-wrapper {
            background: transparent !important;
            border: 0 none !important;
            border-bottom: 1px solid #d9d9d9 !important;
            height: 40px;
            line-height: 40px;
            padding: 0 8px 0 8px;
        }

        .ant-form-horizontal .ant-input-affix-wrapper { padding: 0 0 0 0 !important; }

            .ant-form-horizontal .ant-input-affix-wrapper .ant-input-suffix {
                margin-right: 8px;
            }

.ant-form-item-explain {
    font-size: 12px;
    margin-top: 4px;

    [role=alert] {
        color: #E45858;
    }
}

/* Input */
.curved-input {
    border: 1px solid #D1D1E9;
    border-radius: 10px;
}

/* Checkbox */
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #32B67A !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #32B67A;
    border-color: #32B67A !important;
}

.ant-checkbox:hover::after, .ant-checkbox-wrapper:hover .ant-checkbox::after {
    visibility: hidden;
}

/* Select */
.ant-select.select-plain {}

    .ant-select.select-plain .ant-select-selector {
        background: transparent;
        border: 0 none;
        margin: 0 0 0 0;
        padding: 0 0 0 0;
    }

/* DatePicker */
.ant-picker {
    box-shadow: none;
    padding: 0 12px 0 12px !important;
}

.ant-picker:hover {
    cursor: pointer;
}

.ant-picker .ant-picker-input input {
    border: 0 none !important;
}

/* Button */
.btn-white {
    background: #FFFFFE;
    border: 1px solid #6246EA;
    border-radius: 4px;
    padding: 0px 12px 0 12px;
    font-weight: 500;
    color: #6246EA;
}

.btn-purple {
    background: #6246EA !important;
    border: 1px solid #6246EA !important;
    border-radius: 4px;
    padding: 0px 12px 0 12px;
    font-weight: 500;
    color: #FFFFFE !important;
}

.btn-red {
    background: #E45858 !important;
    border: 1px solid #E45858 !important;
    border-radius: 4px;
    padding: 0px 12px 0 12px;
    font-weight: 500;
    color: #FFFFFE !important;
}

.btn-dashed {
    background: #FFFFFE;
    border: 2px dashed #D1D1E9;
    border-radius: 2px;
    padding: 0px 12px 0 12px;
    font-weight: 500;
    line-height: 40px;
    height: 40px;
    color: #6246EA;
}

.btn-nothing {
    background: transparent !important;
    outline: none !important;
    box-shadow: none !important;
    border: 0 none !important;
    outline: none;
}

.btn-nothing[disabled] {
    color: #8C8C8C !important;
}

.btn-nothing.selected {
    color: #6246EA !important; 
}

/* Table */
table {}

    table thead.ant-table-thead {}

        table thead.ant-table-thead th.ant-table-cell {
            background: transparent;
            padding: 4px 4px 4px 4px !important;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #C0C0C3;
        }

        table thead.ant-table-thead .ant-table-column-sorters {
            padding: 0 0 0 0 !important;
        }

    table tbody.ant-table-tbody {}

        table tbody.ant-table-tbody td.ant-table-cell {
            padding: 4px 4px 4px 4px;
            font-weight: 500;
            font-size: 12px;
            line-height: 32px;
            color: #595959;
        }

    table tr.drop-over-downward td {
        border-bottom: 2px dashed #6246EA;
    }
    
    table tr.drop-over-upward td {
        border-top: 2px dashed #6246EA;
    }

/* Tabs */
.ant-tabs-tab {
    margin: 0 0 0 0 !important;
    padding: 0 12px 12px 12px !important;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
}

/* Modal */
.ant-modal-body {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
}

    .ant-modal-body .modal-header-W {
        background: #6246EA;
        padding: 30px 120px 30px 120px;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        text-align: center;
        color: #FFFFFE;
    }

    @media (max-width: 575px) {
        .ant-modal-body .modal-header-W {
            padding: 15px 15px 15px 15px;
            font-size: 21px;
            line-height: 24px;
        }
    }

        .ant-modal-body .modal-header-W img.header-img {
            width: 100%;
            max-width: 160px;
            margin: 0 auto 0 auto;
        }

    .ant-modal-body .modal-body-W {
        background: #F8F8FB;
    }

        .ant-modal-body .modal-body-W .text-primary {
            font-weight: 500;
            font-size: 20px;
        }

        .ant-modal-body .modal-body-W .text-secondary {
            font-size: 14px;
            color: #8C8C8C;
        }

        .ant-modal-body .modal-body-W .col-header {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: -0.02em;
            text-transform: uppercase;
            color: #8C8C8C;
        }

    .ant-modal-body .confirmation-modal-W {
        background: #6246EA;
        padding: 0 0 5px 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .ant-modal-body .confirmation-modal-W.deletion-modal {
        background: #E45858;
    }

        .ant-modal-body .confirmation-modal-W .header {
            padding: 15px 25px 15px 25px;
        }

        .ant-modal-body .confirmation-modal-W .body {
            background: #FFFFFF;
            margin: 0 5px 0 5px;
            padding: 15px 25px 15px 25px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: #2B2C34;
        }

    .ant-modal-body .modal-footer-W {
        padding: 20px 30px 20px 30px;
    }

.ant-modal-close svg {
    color: #FFFFFF !important;
}

/* Popover */
.ant-popover-arrow {
    display: none;
}

.ant-popover-inner-content {
    padding: 0 0 0 0 !important;
}

/* Card */
.ant-card {
    background: #FFFFFE;
    border: 1px solid #D1D1E9;
    border-radius: 3px;
}

    .ant-card .ant-card-head {
        border: 0 none !important;
        padding: 16px 24px 0 24px;
        min-height: unset;
        line-height: 1.5;
    }

    .ant-card .ant-card-head .ant-card-head-title {
        padding: 0 0 0 0 !important;
    }

    .ant-card .ant-card-body {
        margin: 8px 0 0 0;
        padding: 0 24px 16px 24px;
    }

/* Drawer */
.ant-drawer.no-mask {
    top: 48px;
}

@media (max-width: 767px) {
    .ant-drawer.no-mask {
        top: 0;
        width: 100% !important;
    }
}

    .ant-drawer.no-mask .ant-drawer-body {
        margin: 0 0 0 0;
        padding: 0 0 48px 0;
    }

        .ant-drawer.no-mask .ant-drawer-body .task-view-H {
            background: #F8F8FB;
            box-shadow: inset 0px -1px 0px #DFE0E1;
            padding: 20px 30px 20px 30px;
            font-size: 14px;
            line-height: 17px;
        }

        .ant-drawer.no-mask .ant-drawer-body .task-view-B {
            background: #FFFFFF;
        }

        .ant-drawer.no-mask .ant-drawer-body .task-view-B .form-R1 {
            background: #F8F8FB;
            box-shadow: inset 0px -1px 0px #DFE0E1;
        }

        .ant-drawer.no-mask .ant-drawer-body .task-view-B .form-R1-2 {
            background: #F4F4F9;
            box-shadow: inset 0px -1px 0px #DFE0E1;
        }

            .ant-drawer.no-mask .ant-drawer-body .task-view-B .form-R1-2 label {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #8C8C8C;
            }

            .ant-drawer.no-mask .ant-drawer-body .task-view-B .form-R1-2 p {
                font-weight: 500;
                font-size: 12px;
                line-height: 1.5;
                letter-spacing: -0.02em;
                color: #595959;
            }

        .ant-drawer.no-mask .ant-drawer-body .task-view-B .form-R2 {
            position: relative;
        }

            .ant-drawer.no-mask .ant-drawer-body .task-view-B .form-R2 .details-button {
                position: absolute;
                top: -16px;
                left: calc(50% - 40px);
                background: #F8F8FB;
                border: 1px solid #DFE0E1;
                border-radius: 100px;
                padding: 8px 12px 8px 12px;
                text-align: center;
                font-size: 12px;
                line-height: 16px;
                color: #595959;
                cursor: hand;
                cursor: pointer;
            }

/* SortableHOC */
.drag-handle:hover { cursor: move; }

/* Color Picker */
.color-picker {}

    .color-picker .option {
        display: inline-block;
        margin: 0 8px 0 8px !important;
    }

    .color-picker .option:hover {
        transform: scale(1.20);
        cursor: pointer;
    }

        .color-picker .option.selected .outer {
            border-radius: 100%;
            border: 2px solid #6246EA;
            padding: 3px 3px 3px 3px;
        }

        .color-picker .option .inner {
            border-radius: 100%;
            width: 12px;
            height: 12px;
            margin-bottom: 5px;
        }

        .color-picker .option.selected .inner {
            margin: 0 0 0 0 !important;
        }

/* Tags */
.ant-input.tags-input {
    background: #F8F8FB;
    box-shadow: inset 0px -1px 0px #C4C4C4;
    border: 0 none;
    line-height: 30px;
}

.ant-select.select-tags .ant-select-selector {
    background: #F8F8FB;
    box-shadow: inset 0px -1px 0px #C4C4C4;
    border: 0 none;
    line-height: 30px;
}

    .ant-select.select-tags .ant-select-selector .ant-select-selection-overflow-item .ant-select-selection-item {
        display: none;
    }

.tags-W {}

    .tags-W .tag-C {
        box-shadow: inset 0px -1px 0px #C4C4C4;
    }

        .tags-W .tag {
            background: rgba(101, 122, 221, 0.1);
            border-radius: 100px;
            margin: 8px 8px 8px 8px;
            padding: 4px 8px 4px 8px;
            font-size: 12px;
            line-height: 14px;
            color: #6246EA;
        }

.empty-wrapper {
    background: #FFFFFF;
    border: 1px solid #D1D1E9;
    border-radius: 3px;
    padding: 60px 40px 60px 40px;
    text-align: center;
}




.members-to-add-T,
.members-to-add-T {
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
}

.members-to-add-T table th,
.members-to-add-T table td {
    background: #F8F8FB !important;
}

.members-to-add-T table th {
    color: #8C8C8C !important;
}

.members-added-T table th,
.members-added-T table td {
    background: #FFFFFF !important;
}

.members-added-T table th {
    color: #8C8C8C !important;
}

.members-to-add-T td,
.members-added-T td {
    padding: 0 0 0 0 !important;
}

.select_members-T {
    background: #FFFFFE;
}

    .select_members-T .ant-select-selector {
        border: 1px solid #D1D1E9 !important;
        border-radius: 4px;
        font-size: 12px;
    }
@primary-color: #6246EA;@error-color: #E45858;@text-color: #595959;
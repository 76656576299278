.products--section-header {
    background: #F8F8FB;
    border-bottom: 1px solid #DFE0E1;
    box-sizing: border-box;
    padding: 15px 30px 15px 30px;
}

    .products--section-header .ant-row.ant-form-item.products--search-products-input {
        margin-top: 0 !important;
    }

.products--section-body {
    border-bottom: 1px solid #DFE0E1;
    padding: 32px 32px 32px 32px;
}

    .products-table-C {
        background: rgb(252, 251, 255) !important;
    }

        .products-table-C table {
            background: rgb(252, 251, 255) !important;
            border-collapse: separate !important; 
            border-spacing: 0 10px !important;
        }

            .products-table-C table thead {}

                .products-table-C table thead th.ant-table-cell {
                    background: rgb(252, 251, 255) !important;
                }

            .products-table-C table tbody {}

                .products-table-C table tbody tr {}

                    .products-table-C table tbody td {
                        background: #FFFFFF;
                        border-top: 1px solid #D1D1E9;
                        border-bottom: 1px solid #D1D1E9;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #2B2C34;
                    }

                    .products-table-C table tbody td:first-child {
                        border-left: 1px solid #D1D1E9;
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }

                    .products-table-C table tbody td:last-child {
                        border-right: 1px solid #D1D1E9;
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }

                    .products-table-C table tbody td.productName {
                        font-weight: 500;
                    }

                    .products-table-C table tbody td.actions {}

                        .products-table-C table tbody td.actions .action {
                            padding: 2px 2px 2px 2px;
                        }

                        .products-table-C table tbody td.actions .action:hover {
                            transform: scale(1.15)
                        }

                            .products-table-C table tbody td.actions .action img {
                                height: 14px;
                            }

    .row-dragging {
        background: #FFFFFF;
        border: 1px solid #D1D1E9;
        border-radius: 4px;
        font-family: 'Inter', sans-serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #595959 !important;
    }

    .row-dragging td {
        background: #FFFFFF;
        visibility: hidden;
        padding: 4px 12px 4px 12px;
    }

    .row-dragging .drag-visible {
        visibility: visible;
    }
                    
.add-product-modal-C .modal-body-W.add-product-step-1,
.add-product-modal-C .modal-body-W.add-product-step-3 {
    padding: 30px 120px 30px 120px;
}

@media (max-width: 575px) {
    .add-product-modal-C .modal-body-W.add-product-step-1,
    .add-product-modal-C .modal-body-W.add-product-step-3 {
        padding: 30px 30px 30px 30px;
    }
}
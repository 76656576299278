.form--task-drawer.ant-form-vertical {}

    .form--task-drawer.ant-form-vertical .ant-form-item {
        margin: 16px 0 0 0;
    }

        .form--task-drawer.ant-form-vertical .ant-form-item .ant-form-item-label {
            margin: 0 0 0 0;
            padding: 0 0 0 0;
        }

        .form--task-drawer.ant-form-vertical .task-drawer-custom-input.ant-form-item .ant-form-item-label {
            z-index: 999;
            margin: 0 0 -12px 10px;
            padding: 0 0 0 0;
        }

        .form--task-drawer.ant-form-vertical .task-drawer-custom-input.disabled.ant-form-item .ant-form-item-label {
            margin : 0 0 0 0;
        }

            .form--task-drawer.ant-form-vertical .task-drawer-custom-input.ant-form-item .ant-form-item-label label {
                background: #FFFFFF;
                padding: 0 8px 0 8px;
                font-weight: 400;
                font-size: 15px;
                line-height: 20px;
                color: #C0C0C3;
                text-transform: none;
            }

            .form--task-drawer.ant-form-vertical .task-drawer-custom-input.disabled.ant-form-item .ant-form-item-label label {
                padding: 0 0 0 0;
            }

            .form--task-drawer.ant-form-vertical .task-drawer-custom-input.comandante-rte-wrapper.ant-form-item .ant-form-item-control .ant-form-item-control-input-content {
                border: 1px solid #DFE0E1;
                box-sizing: border-box;
                border-radius: 3px;
                color: #000000;
            }

            .form--task-drawer.ant-form-vertical .task-drawer-custom-input.comandante-rte-wrapper.disabled.ant-form-item .ant-form-item-control .ant-form-item-control-input-content {
                background: #F8F8FB;
            }

                .form--task-drawer.ant-form-vertical .task-drawer-custom-input.ant-form-item .ant-form-item-control .ant-form-item-control-input-content input {
                    border: 1px solid #DFE0E1;
                    box-sizing: border-box;
                    border-radius: 3px;
                    line-height: 36px;
                }

                .form--task-drawer.ant-form-vertical .task-drawer-custom-input.disabled.ant-form-item .ant-form-item-control .ant-form-item-control-input-content input.ant-input-disabled {
                    background: #F8F8FB;
                }

            .form--task-drawer.ant-form-vertical .task-drawer-custom-input.error.comandante-rte-wrapper.ant-form-item .ant-form-item-control .ant-form-item-control-input-content {
                border: 1px solid #E45858;
                box-sizing: border-box;
                border-radius: 3px;
            }

                .form--task-drawer.ant-form-vertical .task-drawer-custom-input.error.ant-form-item .ant-form-item-control .ant-form-item-control-input-content input {
                    border: 1px solid #E45858;
                    box-sizing: border-box;
                    border-radius: 3px;
                    line-height: 36px;
                    color: #000000;
                }

                .form--task-drawer.ant-form-vertical .task-drawer-custom-input.ant-form-item .ant-form-item-control .ant-form-item-control-input-content input.title {
                    padding: 4px 16px 4px 16px;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 40px;
                    color: #000000;
                }

                .form--task-drawer.ant-form-vertical .ant-form-item .ant-form-item-control .ant-form-item-control-input-content textarea {
                    border: 1px solid transparent !important;
                }

                .form--task-drawer.ant-form-vertical .ant-form-item .ant-form-item-control .ant-form-item-control-input-content textarea.checklist-item.to-do {
                    text-decoration-line: none;
                    color: #000000;
                }

                .form--task-drawer.ant-form-vertical .ant-form-item .ant-form-item-control .ant-form-item-control-input-content textarea.checklist-item.done {
                    text-decoration-line: line-through;
                    color: #C0C0C3;
                }
                
.checklist-entry--assignee {
    display: flex;
    x-justify-content: space-around;
    x-align-items: stretch;
    background: #FFFFFE;
    border: 1px solid #DFE0E1;
    box-sizing: border-box;
    border-radius: 3px;
    height: 32px;
    padding: 5px 5px 5px 5px;
    font-size: 13px;
    line-height: 22px;
    color: #595959;
}

.checklist-entry--assignee:hover {
    cursor: pointer;
}

    .checklist-entry--assignee img {
        border-radius: 2px;
        width: 22px;
        flex: 0 0 22px;
    }

h1.title--task-drawer {
    margin: 60px 0 30px 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}

.description--task-drawer {
    margin: 30px 0 60px 0;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}

.dependency-W {
    background: #FFFFFE;
    border: 1px solid #C0C0C3;
    border-radius: 3px;
    font-size: 14px;
    line-height: 18px;
    color: #595959;
}

    .dependency-W .breadcrumbs {
        font-size: 14px;
        line-height: 18px;
        color: #8C8C8C;
    }

    .dependency-W .title {
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        color: #595959;
    }
    
    .dependency-W .priority {
        background: #C0C0C3;
        border-radius: 3px;
        width: 60px;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
    }

    .dependency-W .priority.low { background: #C6EDED !important; color: #6C6D72; }
    .dependency-W .priority.medium { background: #D1FACE !important; color: #6C6D72; }
    .dependency-W .priority.high { background: #FFE3E3 !important; color: #6C6D72; }

.attachments.card-view {
    margin: 10px 0 0 0;
}

    .attachments.card-view .attachment-C {
        background: #FFFFFE;
        border: 1px solid #DFE0E1;
        box-sizing: border-box;
        border-radius: 3px;
        margin-bottom: 8px;
    }

        .attachments.card-view .attachment-C .img-wrapper {
            position: relative;
        }

            .attachments.card-view .attachment-C .img-wrapper img.attachment {
                border-top-left-radius: 3px;
                border-top-right-radius: 3px;
                border-bottom: 1px solid #DFE0E1;
                object-fit: cover;
                width: 100%;
                height: 92px;
            }

            .attachments.card-view .attachment-C .img-wrapper img.uploaded-by {
                position: absolute;
                bottom: -17px;
                left: calc(50% - 17px);
                border: 2px solid #FFFFFE;
                border-radius: 100%;
                object-fit: cover;
                width: 30px;
                height: 30px;
            }

        .attachments.card-view .attachment-C .info-C {}

            .attachments.card-view .attachment-C .info-C .file-name {
                width: 100%;
                font-size: 12px;
                line-height: 14px;
                color: #6246EA;
            }

.logs-W {
    background: #F8F8FB;
    box-shadow: inset 0px -1px 0px #dfe0e1;
}

    .logs-W .activity.log {
        font-size: 12px;
        line-height: 15px;
        color: #8C8C8C;
    }

.activities-W {
    background: #F8F8FB;
}

    .activities-W .activity.log {
        font-size: 12px;
        line-height: 15px;
        color: #8C8C8C;
    }

    .activities-W .activity.comment {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: flex-start;
    }

        .activities-W .activity.comment img.avatar {
            flex: 0 0 30px;
            border-radius: 2px;
            width: 30px;
            height: 30px;
        }

        .activities-W .activity.comment .content-W {
            flex-grow: 1;
            background: #FFFFFE;
            border: 1px solid #C0C0C3;
            box-sizing: border-box;
            border-radius: 3px;
        }

            .activities-W .activity.comment .content-W .datetime {
                font-size: 12px;
                line-height: 15px;
                text-align: right;
                color: #595959;
            }

            .activities-W .activity.comment .content-W .sender {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #2B2C34;
            }

                .activities-W .activity.comment .content-W .comment-body p {
                    margin: 0 0 0 0;
                    font-size: 14px;
                    line-height: 17px;
                    color: #595959;
                }

            .activities-W .activity.comment .content-W .replies.collapsing-replies {}

                .activities-W .activity.comment .content-W .replies.collapsing-replies .ant-collapse-header {
                    padding: 0 0 0 0 !important;
                }

                .activities-W .activity.comment .content-W .replies.collapsing-replies .ant-collapse-item {
                    display: flex;
                    flex-direction: column-reverse;
                }

                .activities-W .activity.comment .content-W .replies.collapsing-replies .ant-collapse-content-box {
                    padding: 0 0 0 0;
                }
            
            .activities-W .activity.comment .content-W .replies.collapsing-replies .btn-expand {
                display: block;
                background: #F8F8FB !important;
                border-top: 1px solid #C0C0C3 !important;
                box-sizing: border-box;
                border-radius: 0px 0px 3px 3px;
                width: 100%;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #595959;
            }

    .activities-editor-W {
        position: relative;
        background: #FFFFFE;
    }

    .activities-editor-W .btn-comment {
        position: absolute;
        bottom: 8px;
        right: 8px;
    }

    @media (max-width: 575px) {
        .activities-editor-W .btn-comment {
            z-index: 99999 !important;
        }
    }

    .replies-W {}
    
        .replies-W .reply {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: flex-start;
        }
    
            .replies-W .reply img.avatar {
                flex: 0 0 30px;
                border-radius: 2px;
                width: 30px;
                height: 30px;
            }
    
            .replies-W .reply .content-W {
                flex-grow: 1;
                background: #F8F8FB !important;
                border: 1px solid #DFE0E1;
                box-sizing: border-box;
                border-radius: 3px;
                margin: 0 16px 0 16px;
            }
    
                .replies-W .reply .content-W .datetime {
                    font-size: 12px;
                    line-height: 30px !important;
                    text-align: right;
                    color: #595959;
                }
    
                .replies-W .reply .content-W .sender {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 30px !important;
                    color: #2B2C34;
                }
    
                    .replies-W .reply .content-W .comment-body p {
                        margin: 0 0 0 0;
                        font-size: 14px;
                        line-height: 17px;
                        color: #595959;
                    }

.image-gallery-modal {
}

    .image-gallery-modal .image-gallery-modal-header {
        background: #6246EA;
        color: #FFFFFE;
    }

        .image-gallery-modal .image-gallery-modal-header .file-name {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #FFFFFE;
        }

        .image-gallery-modal .image-gallery-modal-header .file-type {
            font-size: 12px;
            line-height: 18px;
            color: #D1D1E9;
        }

    .image-gallery-modal .image-gallery-modal-body {
        background: #313131;
        height: calc(100vh - 84px);
    }

        .image-gallery-modal .image-gallery-modal-body img.attachment {
            max-width: 992px;
            max-height: calc(100vh - 150px);
        }

.viewTaskDrawer--unarchive-stage-select-box .ant-select-selector {
    border: 1px solid #D1D1E9 !important;
    border-radius: 10px !important;
}
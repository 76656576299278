.editUserModal {
}

    .editUserModal .editUserModal-header {
        background: #F8F8FB;
        padding: 80px 30px 15px 30px;
        text-align: center;
    }

    .editUserModal .editUserModal-header h2 {
        margin: 20px 0 0 0;
        font-size: 24px;
        line-height: 1.2;
        color: #595959;
    }

    .editUserModal .editUserModal-body {
        background: #F8F8FB;
        padding: 15px 90px 30px 90px;
    }

    .editUserModal-footer {
        background: #F8F8FB;
        padding: 15px 90px 90px 90px;
    }

    @media (max-width: 991px) {
        .editUserModal .editUserModal-header {
            padding: 30px 30px 30px 30px;
        }

        .editUserModal .editUserModal-body {
            padding: 30px 30px 30px 30px;
        }
    }
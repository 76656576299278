.users--section-header {
    background: #F8F8FB;
    border-bottom: 1px solid #DFE0E1;
    box-sizing: border-box;
    padding: 15px 30px 15px 30px;
}

    .users--section-header .ant-row.ant-form-item.users--search-users-input {
        margin-top: 0 !important;
    }

.users--section-body {
    background: #FFFFFE;
    border-bottom: 1px solid #DFE0E1;
    padding: 32px 32px 32px 32px;
}

@media (max-width: 575px) {
    .users--section-header {
        padding: 30px 30px 30px 30px;
    }

    .users--section-body {
        padding: 30px 30px 30px 30px;
    }
}

@media (max-width: 575px) {
    .users-table table td.member-col {
        width: 100%;
    }   
}
.addUserModal {
}

    .addUserModal .addUserModal-header {
        background: #F8F8FB;
        padding: 80px 30px 15px 30px;
        text-align: center;
    }

    @media (max-width: 575px) {
        .addUserModal .addUserModal-header {
            padding: 15px 15px 15px 15px;
        }
    }

    .addUserModal .addUserModal-header img.logo {
        display: block;
        margin: 0 auto 20px auto;
    }

    .addUserModal .addUserModal-header h2 {
        margin: 20px 0 0 0;
        font-size: 24px;
        line-height: 1.2;
        color: #595959;
    }

    .addUserModal .addUserModal-body {
        background: #F8F8FB;
        padding: 15px 90px 30px 90px;
    }

    @media (max-width: 575px) {
        .addUserModal .addUserModal-body {
            padding: 15px 15px 15px 15px;
        }
    }

        .addUserModal .addUserModal-step1 .addUserModal-body {
        }

            .addUserModal .addUserModal-step1 .addUserModal-body .password-rules {
                margin: 4px 0 0 0;
            }

                .addUserModal .addUserModal-step1 .addUserModal-body .password-rules .rule {
                    display: inline-block;
                    width: 48%;
                    margin: 0 0 4px 0;
                    font-size: 12px;
                }

                    .addUserModal .addUserModal-step1 .addUserModal-body .password-rules .rule .box {
                        display: inline-block;
                        background: #efefef;
                        border: 1px solid #D1D1E9;
                        width: 10px;
                        height: 10px;
                        margin: 0 4px 0 0;
                    }

                    .addUserModal .addUserModal-step1 .addUserModal-body .password-rules .rule.success .box {
                        background: #3f8e2b;
                    }

                    .addUserModal .addUserModal-step1 .addUserModal-body .password-rules .rule.danger .box {
                        background: #d5615d;
                    }

        .addUserModal .addUserModal-step2 .addUserModal-body {
        }
        
            .addUserModal .addUserModal-step2 .addUserModal-body .terms-of-use {
                margin: 20px 0 0 0;
                font-size: 12px;
                line-height: 1.25;
                color: #8C8C8C;
            }

    .addUserModal-footer {
        background: #F8F8FB;
        padding: 15px 90px 90px 90px;
    }

    @media (max-width: 575px) {
        .addUserModal-footer {
            padding: 15px 15px 90px 15px;
        }   
    }
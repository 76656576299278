.login-W {
    margin-top: 180px;
    width: 300px;
}

    .login-W img {
        width: 100%;
        max-width: 240px;
    }

    .login-W h1 {
        margin-top: 50px !important;
        font-weight: 500;
        font-size: 28px;
        line-height: 34px;
        color: #595959;
    }

    .login-W form {
        margin-top: 30px;
    }

        .login-W form .ant-input-password-icon {
            margin-top: -60px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #6246EA !important;
        }

.copyright-helper {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #C0C0C3;
}

.login-BG-wrapper {}

    .login-BG-wrapper img {
        width: 100%;
        max-width: 720px;
    }
.task-W {
    background: #FFFFFF;
    box-shadow: 0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108);
    border-radius: 3px;
    margin: -2px 0 12px 0;
    font-size: 14px;
    line-height: 18px;
    color: #2B2C34;
}

.task-W:hover { cursor: pointer; }
.task-W.disabled:hover { cursor: unset; }

    .task-W img {
        width: 100%;
        max-height: 120px;
        object-fit: cover; 
        margin: 0 0 8px 0; 
    }

    .task-W .title {
        padding: 10px 10px 10px 10px;
    }

        .task-W .title a {
            color: #2B2C34 !important;
        }

    .task-W .info-C {
        background: #F8F8FB;
        padding: 10px 10px 10px 10px;
    }

        .task-W .info-C .R1 {}

            .task-W .info-C .R1 .assignee {
                font-size: 12px;
                line-height: 12px;
                color: #000000;
            }

            .task-W .info-C .R1 .assignee.empty {
                color: #C0C0C3;
            }

                .task-W .info-C .R1 .assignee img {
                    width: 16px;
                    height: 16px;
                }

            .task-W .R1 .priority {
                background: #C0C0C3;
                border-radius: 3px;
                width: 60px;
                text-align: center;
                font-size: 12px;
                line-height: 14px;
                color: #FFFFFF;
            }

            .task-W .priority.low { background: #C6EDED !important; color: #6C6D72; }
            .task-W .priority.medium { background: #D1FACE !important; color: #6C6D72; }
            .task-W .priority.high { background: #FFE3E3 !important; color: #6C6D72; }

        .task-W .info-C .R2 {
            color: #6246EA;
        }

.task-W.list {}

    .task-W.list .title {
        padding: 8px 8px 4px 8px;
    }

    .task-W.list .info-C {
        background: unset;
        padding: 4px 8px 8px 8px;
    }

    .task-W.list .info-C .assignee {
        font-size: 12px;
        line-height: 12px;
        color: #000000;
    }

    .task-W.list .info-C .assignee.empty {
        color: #C0C0C3;
    }

        .task-W.list .info-C .assignee img {
            width: 16px;
            height: 16px;
        }

    .task-W.list .priority {
        background: #C0C0C3;
        border-radius: 3px;
        width: 60px;
        text-align: center;
        font-size: 12px;
        line-height: 14px;
        color: #FFFFFF;
    }

    .task-W.list .priority.low { background: #C6EDED !important; color: #595959; }
    .task-W.list .priority.medium { background: #D1FACE !important; color: #595959; }
    .task-W.list .priority.high { background: #FFE3E3 !important; color: #595959; }
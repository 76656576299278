img.selected-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 24px;
    margin: 3px 0 3px 0;
}

aside.ant-layout-sider {
    background: #FFFFFF;
    border-right: 1px solid rgba(209, 209, 233, 0.6);
    height: calc(100vh - 48px);
    flex: 0 0 275px !important;
    max-width: 275x !important;
    min-width: 275px !important;
    width: 275px !important;
    z-index: 999;
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
}

.ant-menu {
    background: #FFFFFF !important;
    border-right: 0 none !important;
    overflow: scroll;
    height: calc(100% - 96px);
    margin-top: 12px !important;
}

.ant-menu::-webkit-scrollbar {
    display: none;
}

    .ant-menu .ant-menu-item {
        background: #FFFFFF;
        border-right: 1px solid rgba(209, 209, 233, 0.6) !important;
        height: 30px !important;
        margin: 5px 0 5px 0 !important;
        padding: 0 0 0 0 !important;
        font-size: 13px;
        line-height: 30px !important;
    }

    .ant-menu .ant-menu-item.ant-menu-item-only-child {
        border-right: 0 none !important;
    }

    .ant-menu .ant-menu-item.ant-menu-item-selected {
        position: relative;
        background: #F8F8FB !important;
    }

        .ant-menu .ant-menu-item a {
            height: 30px;
            line-height: 30px;
            font-weight: 500;
            color: #595959;
        }

        .ant-menu .ant-menu-item a .nav-item-C {
            padding: 0 16px 0 16px;
        }
            .ant-menu .ant-menu-item a .nav-item-C .icon-sidebar {
                height: 12px;	
                margin: 0 4px 4px 0;
            }
        
            .ant-menu .ant-menu-item a .nav-item-C .icon-sidebar.project {
                height: 6px;
                margin: 0 8px 2px 3px;
            }

    .ant-menu .ant-menu-item.ant-menu-item-selected {
        background: #FFFFFF;
    }

    .ant-menu .ant-menu-item.ant-menu-item-selected::after {
        border: 0 none;
    }

        .ant-menu .ant-menu-item.ant-menu-item-selected a {
            color: #6246EA;
        }

    .ant-menu .ant-menu-submenu {
        padding: 4px 0 4px 0 !important;
    }

    .ant-menu .ant-menu-submenu .ant-menu-item {
        height: 30px !important;
    }

            .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
                position: relative;
                height: 30px !important;
                margin: 0 0 0 0 !important;
                padding: 0 0 0 0 !important;
                line-height: 30px !important;
            }

                .ant-menu .ant-menu-submenu .ant-menu-submenu-title a {
                    font-weight: 500;
                    font-size: 13px;
                    color: #595959;
                }

                .ant-menu .ant-menu-submenu .ant-menu-submenu-title .ant-menu-submenu-arrow {
                    display: none;
                }

                    .ant-menu .ant-menu-submenu .ant-menu-submenu-title a .submenu-title-C {
                        padding: 0 12px 0 12px;
                        display: inline-flex !important;
                        align-items: center !important;
                    }

            .ant-menu .ant-menu-submenu .ant-menu-sub {
                margin: 0 0 0 0 !important;
            }

                .ant-menu .ant-menu-submenu .ant-menu-sub .ant-menu-item {
                    height: 30px !important;
                    line-height: 30px !important;
                    margin: 0 0 0 0;
                }

.ant-menu .menuItem-project {
    align-items: center !important;
    border-radius: 8px;
    width: calc(100% - 24px);
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
}

.ant-menu .ant-menu-item-selected.menuItem-project {
    background: #FFFFFF;
    border-radius: 0;
    font-weight: 500;
}

    .ant-menu .ant-menu-item-selected.menuItem-project::after {
        border: 0 none;
    }

    .ant-menu .menuItem-project a {
        line-height: 17px;
    }

        .ant-menu .menuItem-project a .project-action-buttons-C {
            display: none;
        }

        .ant-menu .menuItem-project:hover a .project-action-buttons-C {
            display: block;
        }

        .ant-menu .menuItem-favourites a .project-action-buttons-C {
            display: none;
        }

        .ant-menu .menuItem-favourites:hover a .project-action-buttons-C {
            display: block;
        }

        .ant-menu .menuItem-project a .submenu-item-C {
            display: inline-flex;
            align-items: center;
            padding: 0 0 0 31px;
        }

.ant-menu .ant-menu-item-group {
    margin: 12px 0 0 0;
}

    .ant-menu .ant-menu-item-group .ant-menu-item-group-title {
        display: none;
    }

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
    width: calc(100%) !important;
}

.ant-layout-sider-actions {
    position: fixed;
    bottom: 0;
    background: #FFFFFF !important;
    border-top: 1px solid #F7F7F7;
    border-right: 1px solid rgba(209, 209, 233, 0.6) !important;
    width: 275px !important;
    font-weight: 500;
    font-size: 14px;
    line-height: 36px;
}

    .ant-layout-sider-actions .text-purple {
        color: #6246EA !important;
    }

    .ant-layout-sider-actions .text-grey {
        color: #737373 !important;
    }

.table-section-header {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #8C8C8C;
}
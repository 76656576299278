.team--section-header {
    background: #F8F8FB;
    border-bottom: 1px solid #DFE0E1;
    box-sizing: border-box;
    padding: 15px 30px 15px 30px;
}

    .team--section-header .ant-row.ant-form-item.team--search-teams-input {
        margin-top: 0 !important;
    }

.teams-W {}
    .teams-W .team {
        background: #FFFFFE;
        border: 1px solid #D1D1E9;
        box-sizing: border-box;
        border-radius: 3px;
    }

        .teams-W .team .team--products-and-projects {
            height: 240px;
            padding: 12px 16px 12px 16px;
            font-size: 16px;
            line-height: 19px;
        }

            .teams-W .team .team--products-and-projects .header {
                background: #FFFFFE;
                border: 0 none !important;
                font-weight: 500;
                font-size: 18px;
                line-height: 32px;
            }

            .teams-W .team .team--products-and-projects .helper {
                margin-top: 10px;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #C0C0C3;
            }

        .teams-W .team .team--team-members {
            background: #F8F8FB;   
            border-top: 1px solid #D1D1E9;
            height: 240px;
            padding: 12px 16px 12px 16px;
            font-size: 16px;
            line-height: 32px;
        }

            .teams-W .team .team--team-members .members-T {
                overflow-y: scroll;
                max-height: 200px !important;
            }

                .teams-W .team .team--team-members .members-T th {
                    border: 0 none !important;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    letter-spacing: -0.02em;
                    text-transform: uppercase;
                    color: #C0C0C3 !important;
                }

                .teams-W .team .team--team-members .members-T td {
                    border: 0 none !important;
                    padding: 4px 4px 4px 4px !important;
                }

                .teams-W .team .team--team-members .members-T td:first-child {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 18px;
                    color: #595959;
                }

                .teams-W .team .team--team-members .members-T td:last-child {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: #8C8C8C;
                }

        .empty-helper {
            padding: 40px 0 40px 0;
        }

            .empty-helper .primary {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                text-align: center;
                color: #6246EA;
            }
            
            .empty-helper .secondary {
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                color: #8C8C8C;
            }

ul.manage-projects-M.ant-dropdown-menu {
    /* overflow-y: scroll; */
    /* max-height: 190px !important; */
    margin: 0 0 0 0 !important;
    padding: 0 0 0 0 !important;
}

ul.manage-projects-M.ant-dropdown-menu::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}

    ul.manage-projects-M.ant-dropdown-menu .ant-dropdown-menu-item {
        background: #FFFFFE;
        box-shadow: inset 0px -1px 0px #C4C4C4;
        margin: 0 0 0 0 !important;
        padding: 10px 12px 10px 12px !important;
    }

    ul.manage-projects-M.ant-dropdown-menu li.ant-dropdown-menu-submenu {}

    ul.manage-projects-M.ant-dropdown-menu li.ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title {
        background: #FFFFFE;
        box-shadow: inset 0px -1px 0px #C4C4C4;
        margin: 0 0 0 0 !important;
        padding: 10px 12px 10px 12px !important;
    }

        ul.manage-projects-M.ant-dropdown-menu li.ant-dropdown-menu-submenu .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
            bottom: 8px;   
            right: 16px;
        }

        .ant-dropdown-menu.ant-dropdown-menu-sub {
            margin: 0 0 0 0 !important;
            padding: 0 0 0 0 !important;
        }
        
            .ant-dropdown-menu.ant-dropdown-menu-sub li.ant-dropdown-menu-item.project-C {
                background: #F8F8FB;
                box-shadow: inset 0px -1px 0px #C4C4C4;
                width: 240px;
                padding: 8px 12px 8px 12px !important;
            }

.team--collapsing-products {
    border: 0 none !important;
}            

    .team--collapsing-products .ant-collapse-item {
        background: #FFFFFE;
        border: 0 none !important;
        font-weight: 500;
        font-size: 13px;
    }

        .team--collapsing-products .ant-collapse-item .anticon.anticon-caret-right.ant-collapse-arrow {
            padding-top: 2px !important;
            left: 0 !important;
        }

        .team--collapsing-products .ant-collapse-item .ant-collapse-header {
            padding: 2px 0 2px 0 !important;
        }

        .team--collapsing-products .ant-collapse-item .ant-collapse-content,
        .team--collapsing-products .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
            border: 0 none !important;
            padding: 0 0 0 0 !important;
        }

        .team--collapsing-products .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
            margin: 0 0 0 5px !important;
            border-left: 1px solid #C0C0C3 !important;
        }

            .team--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C,
            .team--collapsing-products .ant-collapse-item .ant-collapse-content .project {
                border: 0 none !important;
            }

            .team--collapsing-products .ant-collapse-item .ant-collapse-content .project {
                box-shadow: unset !important;
            }

                .team--collapsing-products .ant-collapse-item .ant-collapse-content .project .info .name {
                    font-weight: 400 !important;
                    font-size: 13px !important;
                    color: #595959 !important;
                }

.manage-products-modal--collapsing-products {
    border: 0 none !important;
}

    .manage-products-modal--collapsing-products .ant-collapse-item {
        border: 0 none !important;
        border-top: 1px solid #DFE0E1 !important;
    }

    .manage-products-modal--collapsing-products .ant-collapse-item {
        background: #FFFFFE;
    }

    .manage-products-modal--collapsing-products.found .ant-collapse-item {
        background: #F8F8FB;
    }

    .manage-products-modal--collapsing-products.added .ant-collapse-item {
        background: #FFFFFE;
    }

        .manage-products-modal--collapsing-products .ant-collapse-item .anticon.anticon-caret-right.ant-collapse-arrow {
            padding-top: 8px !important;
        }

        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-header {
            border: 0 none !important;
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }

        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content,
        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
            border: 0 none !important;
            padding: 0 0 0 0 !important;
        }

            .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C {
                padding: 0 0 0 0;
            }
        
                .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    border: 0 none;
                    border-radius: 0;
                    max-height: calc(100% - 104px);
                }

                .manage-products-modal--collapsing-products.found .ant-collapse-item .ant-collapse-content .projects-C .project {
                    background: #FFFFFE;
                }

                .manage-products-modal--collapsing-products.added .ant-collapse-item .ant-collapse-content .projects-C .project {
                    background: #F8F8FB;
                }
        
                .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project:hover {
                    box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
                }
        
                    .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-1 {
                        flex: 1;
                        min-width: 0;
                    }
        
                        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-1-inner {
                            display: flex;
                            flex-direction: row;
                            flex-wrap: nowrap;
                            justify-content: flex-start;
                            align-items: center;
                        }
        
                        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-1 .img-W {
                            width: 20px;
                            flex: 0 0 20px;
                        }
        
                            .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-1 .img-W img.icon {
                                height: 6px;
                            }
        
                        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-1 .info {
                            width: 100%;
                        }
        
                            .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-1 .info .name {
                                min-width: 0;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-weight: 500;
                                font-size: 14px;
                                line-height: 17px;
                                color: #000000;
                            }
        
                            .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-1 .info .extra {
                                min-width: 0;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: 15px;
                                color: #8C8C8C;
                            }
        
                    .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-2 {
                        font-weight: 400;
                        font-size: 12px;
                        color: #595959;
                    }
        
                        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-2 .icon {
                            height: 13px;
                            margin: 0 0 2px 0;
                        }
        
                        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-2 .icon.separated {
                            height: 24px;
                            border-right: 1px solid #D1D1E9;
                            border-left: 1px solid #D1D1E9;
                            padding: 0 12px 0 12px;
                        }
        
                        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-2 .icon.star {
                            height: 20px;
                        }
        
                        .manage-products-modal--collapsing-products .ant-collapse-item .ant-collapse-content .projects-C .project .project-col-2 .icon.action:hover {
                            transform: scale(1.15);
                        }
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;500;700&display=swap');

html, body {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

/*
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}
*/
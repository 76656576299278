.project--section-header {
    background: #F8F8FB;
    border-bottom: 1px solid #DFE0E1;
    box-sizing: border-box;
    padding: 15px 15px 15px 15px;
    position: fixed;
    top: 50px;
    left: 275px;
    right: 0;
}

.project--section-body {
    margin-top: 75px;
}

@media (max-width: 767px) {
    .project--section-header {
        position: relative;
        top: unset;
        left: unset;
        right: unset;
    }

    .project--section-body {
        margin-top: 0px;
    }
}

    .project--sort-tasks-select-box .ant-select-selector {
        border: 1px solid #D1D1E9 !important;
        border-radius: 10px !important;
    }

    .project--header-tabs.ant-tabs {
        margin: 0 0 0 8px;
    }
    
        .project--header-tabs.ant-tabs .ant-tabs-nav {            
            position: unset !important;
            margin: 0 0 0 0 !important;
        }

            .project--header-tabs.ant-tabs .ant-tabs-nav .ant-tabs-tab {
                margin: 0 0 0 16px !important;
                padding: 0 0 0 0 !important;
            }

                .project--header-tabs.ant-tabs .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-btn {
                    line-height: 1;
                    color: #8C8C8C !important;
                }

                .project--header-tabs.ant-tabs .ant-tabs-nav .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                    color: #6246EA !important;
                }

            .project--header-tabs.ant-tabs .ant-tabs-nav .ant-tabs-ink-bar {
                display: none;
            }

.sort-options {
    font-size: 14px;
    line-height: 18px;
    color: #6C6D72;
}
    .sort-options .select-plain {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #6C6D72;
    }

.stages-C.board {
    display: flex;
}

    .stages-C.board .stage-W {
        flex: 0 0 auto;
        vertical-align: top;
        width: 280px;
    }

        .stages-C.board .stage-column {
            overflow-y: scroll;
            border-radius: 4px;
            padding: 8px 12px 24px 12px;
            height: calc(100vh - 160px);
        }

            .stages-C.board .stage-column .stage-label {
                font-size: 12px;
            }

            .stages-C.board .stage-column .stage-action {
                padding: 0 0 0 0 !important;
                font-size: 12px;
                color: #6246EA;
            }

.stages-C.list {
}

    .stages-C.list .stage-W {
    }

    .stages-C.list .stage-W.task-open {
        /* transition: margin-right .5s; */
        /* margin-right: 710px; */
    }

        .stages-C.list .stage-column {
            overflow-y: scroll;
            border-radius: 4px;
            padding: 8px 12px 24px 12px;
        }

            .stages-C.list .stage-column .stage-label {
                font-size: 12px;
            }

            .stages-C.list .stage-column .stage-action {
                padding: 0 0 0 0 !important;
                font-size: 12px;
                color: #6246EA;
            }
.home--collapsing-cards {
    border: 0 none !important;
}

    .home--collapsing-cards .ant-collapse-item {
        background: rgb(252, 251, 255) !important;
        border: 0 none !important;
        margin: 12px 0 12px 0;
    }

        .home--collapsing-cards .ant-collapse-item .ant-collapse-header {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            color: #8C8C8C !important;
        }

    .home--collapsing-cards .ant-collapse-content {
        background: transparent;
        border: 0 none;
        box-sizing: border-box;
        border-radius: 3px !important;
    }

        .home--collapsing-cards .ant-collapse-content .ant-collapse-content-box {
            margin: 0 0 0 0 !important;
            padding: 0 0 0 0 !important;
        }

        .home--collapsing-cards .ant-collapse-content .content {
            padding: 10px 20px 10px 20px;
        }

        .home--collapsing-cards .ant-collapse-content .content.empty {
            background: #F8F8FB;
            border: 1px solid #DFE0E1;
            box-sizing: border-box;
            border-radius: 3px;
            margin: 0 20px 0 20px;
            color: #8C8C8C;
        }

.home--collapsing-cards .task {
    background: #FFFFFF;
    box-shadow: 0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108);
    border-radius: 3px;
    margin: 0 0 12px 0;
    font-size: 14px;
    line-height: 18px;
    color: #2B2C34;
}

    .home--collapsing-cards .task:hover {
        cursor: pointer;
    }

        .home--collapsing-cards .task img {
            width: 100%;
            max-height: 120px;
            object-fit: cover; 
            margin: 0 0 8px 0; 
        }

        .home--collapsing-cards .task .title {
            padding: 10px 10px 10px 10px;
        }

            .home--collapsing-cards .task .title a {
                color: #2B2C34 !important;
            }

        .home--collapsing-cards .task .breadcrumbs {
            padding: 0 0 10px 0;
            color: #C0C0C3;
        }

        .home--collapsing-cards .task .info-C {
            background: #F8F8FB;
            padding: 10px 10px 10px 10px;
        }

            .home--collapsing-cards .task .info-C .R1 {}

                .home--collapsing-cards .task .info-C .R1 .assignee {
                    font-size: 12px;
                    line-height: 12px;
                    color: #000000;
                }

                .home--collapsing-cards .task .info-C .R1 .assignee.empty {
                    color: #C0C0C3;
                }

                    .home--collapsing-cards .task .info-C .R1 .assignee img {
                        width: 16px;
                        height: 16px;
                    }

                .home--collapsing-cards .task .R1 .priority {
                    background: #C0C0C3;
                    border-radius: 3px;
                    width: 60px;
                    text-align: center;
                    font-size: 12px;
                    line-height: 14px;
                    color: #FFFFFF;
                }

            .home--collapsing-cards .task .priority.low { background: #C6EDED !important; color: #6C6D72; }
            .home--collapsing-cards .task .priority.medium { background: #D1FACE !important; color: #6C6D72; }
            .home--collapsing-cards .task .priority.high { background: #FFE3E3 !important; color: #6C6D72; }

            .home--collapsing-cards .task .info-C .R2 {
                color: #6246EA;
            }
.notifications--section-header {
    background: #F8F8FB;
    border-bottom: 1px solid #DFE0E1;
    box-sizing: border-box;
    padding: 15px 30px 15px 30px;
}

@media (max-width: 575px) {
    .notifications--section-header {
        padding: 10px 15px 10px 15px;
    }   
}

    .notifications--section-header .ant-row.ant-form-item.notifications--search-notifications-input {
        margin-top: 0 !important;
    }

.notifications--section-body {
    padding: 32px 32px 32px 32px;
}

@media (max-width: 575px) {
    .notifications--section-body {
        padding: 30px 15px 10px 15px;
    }   
}

.notification {
    background: #FFFFFE;
    border: 1px solid #D1D1E9;
    box-sizing: border-box;
    box-shadow: 0px 1.6px 3.6px rgba(0, 0, 0, 0.132), 0px 0.3px 0.9px rgba(0, 0, 0, 0.108);
    border-radius: 3px;
    max-width: 612px;
}

    .notification .header {}

        .notification .header .btn-archive {
            color: #6246EA;
        }

        .notification .header .label {
            line-height: 32px;
        }

        .notification .header .date-time {
            font-size: 12px;
            line-height: 32px;
            color: #6C6D72;
        }

    .notification .content {
        font-size: 14px;
        line-height: 18px;
        color: #8C8C8C;
    }

        .notification .content img.pp {
            height: 18px;
        }

        .notification .content .notifier {
            font-weight: 500;
            color: #2B2C34;
        }

        .notification .content .reference {
            border-left: 1px solid#C0C0C3;
            margin: 8px 0 0 9px;
            padding: 0 8px 0 17px;
            font-size: 14px;
            line-height: 18px;
            color: #595959;
        }

    .notification .footer {
        background: #F8F8FB;
        border-top: 1px solid #DFE0E1;
        font-size: 14px;
        line-height: 17px;
        color: #595959;
    }

        .notification .footer .breadcrumbs {
            font-size: 12px;
            line-height: 15px;
            color: #6C6D72;
        }
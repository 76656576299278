.details-R {
    background: #FFFFFE;
    border-bottom: 1px solid #DFE0E1;
}

    .details-header {
        background: #F8F8FB;
    }
    
    .col-border-right {
        border-right: 1px solid #DFE0E1;
    }

    .product-details-C {
        background: #FFFFFE;
        border-top: 1px solid #DFE0E1;
        padding: 32px 32px 32px 32px;
    }

        .product-details-C .details-row-flex {
            display: flex;
            x-justify-content: space-around;
            x-align-items: stretch;
            border-bottom: 1px solid #DFE0E1;
        }

        .product-details-C .details-row-flex:last-child {
            border: 0 none;
        }

            .product-details-C .details-row-flex .label {
                width: 100px;
                flex: 0 0 100px;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #8C8C8C;
            }

            .product-details-C .details-row-flex .value {
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: #595959;
            }

    .projects-C {
        overflow-y: scroll;
        background: #FFFFFE;
        border-top: 1px solid #DFE0E1;
        padding: 32px 32px 32px 32px;
        max-height: 329px;
    }

    @media (max-width: 767px) {
        .projects-C {
            overflow-y: auto;
            max-height: unset;
        }
    }

        .projects-C .project {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: center;
            background: #FFFFFE;
            border: 1px solid #D1D1E9;
            border-radius: 3px;
            max-height: calc(100% - 104px);
        }

        .projects-C .project:hover {
            box-shadow: 0px 3.2px 7.2px rgba(0, 0, 0, 0.132), 0px 0.6px 1.8px rgba(0, 0, 0, 0.108);
        }

            .projects-C .project .project-col-1 {
                flex: 1;
                min-width: 0;
            }

                .projects-C .project .project-col-1-inner {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-start;
                    align-items: center;
                }

                .projects-C .project .project-col-1 .img-W {
                    width: 20px;
                    flex: 0 0 20px;
                }

                    .projects-C .project .project-col-1 .img-W img.icon {
                        height: 6px;
                    }

                .projects-C .project .project-col-1 .info {
                    width: 100%;
                }

                    .projects-C .project .project-col-1 .info .name {
                        min-width: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        color: #000000;
                    }

                    .projects-C .project .project-col-1 .info .extra {
                        min-width: 0;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: #8C8C8C;
                    }

            .projects-C .project .project-col-2 {
                font-weight: 400;
                font-size: 12px;
                color: #595959;
            }

                .projects-C .project .project-col-2 .icon {
                    height: 13px;
                    margin: 0 0 2px 0;
                }

                .projects-C .project .project-col-2 .icon.separated {
                    height: 24px;
                    border-right: 1px solid #D1D1E9;
                    border-left: 1px solid #D1D1E9;
                    padding: 0 12px 0 12px;
                }

                .projects-C .project .project-col-2 .icon.star {
                    height: 20px;
                }

                .projects-C .project .project-col-2 .icon.action:hover {
                    transform: scale(1.15);
                }

    .members-C {
        background: #FFFFFE;
        border-top: 1px solid #DFE0E1;
        border-bottom: 1px solid #DFE0E1;
        padding: 32px 32px 32px 32px;
    }

#form_manageStages.ant-form-vertical {}

    #form_manageStages.ant-form-vertical .ant-form-item {
        margin: 8px 0 0 0 !important;
    }

        #form_manageStages.ant-form-vertical .ant-form-item .ant-form-item-label {
            margin: 0 0 0 0;
            padding: 0 0 0 0 !important;
        }

            #form_manageStages.ant-form-vertical .ant-form-item .ant-form-item-label label {
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: -0.02em;
                text-transform: uppercase;
                color: #8C8C8C;
            }

        #form_manageStages.ant-form-vertical .ant-form-item .ant-form-item-control {
            margin: 0 0 0 0;
        }

            #form_manageStages.ant-form-vertical .ant-form-item .ant-form-item-control .ant-input-affix-wrapper {
                border: 1px solid #d9d9d9 !important;
                outline: none !important;
                box-shadow: none !important;
            }
            
            #form_manageStages.ant-form-vertical .ant-form-item .ant-form-item-control .ant-input-affix-wrapper.stage.locked {
                background: #f2f0f0;
            }

                #form_manageStages.ant-form-vertical .ant-form-item .ant-form-item-control .ant-input-affix-wrapper input {
                    cursor: auto;
                }

                #form_manageStages.ant-form-vertical .ant-form-item .ant-form-item-control .ant-input-affix-wrapper.stage.locked input {
                    background: #f2f0f0 !important;
                }

ul.ant-dropdown-menu.ant-dropdown-menu-sub {
    width: 120px !important;
}

.add-project-modal-C .modal-body-W.add-project-step-1,
.add-project-modal-C .modal-body-W.add-project-step-4,
.add-project-modal-C .modal-body-W.add-project-step-5,
.edit-product-modal-C .modal-body-W.edit-product-step-1 {
    padding: 30px 120px 30px 120px;
}

@media (max-width: 575px) {
    .add-project-modal-C .modal-body-W.add-project-step-1,
    .add-project-modal-C .modal-body-W.add-project-step-4,
    .add-project-modal-C .modal-body-W.add-project-step-5,
    .edit-product-modal-C .modal-body-W.edit-product-step-1 {
        padding: 30px 30px 30px 30px;
    }
}
.header-C {
    background: #FFFFFF !important;
    border-bottom: 1px solid rgba(209, 209, 233, 0.6);
    margin: 0 0 0 0 !important;
    padding: 0 20px 0 20px !important;
    height: 48px !important;
    line-height: 48px !important;
}

    .header-C img {
        height: 21px;
    }

    .header-C .actions-W {
        float: right;
    }

        .header-C .actions-W .action {
            border: 0 none !important;
            outline: none !important;
            margin: 0 0 0 0;
            padding: 0 0 0 0;
        }

        .header-C .actions-W .action[disabled] {
            background: #FFFFFF !important;
        }

            .header-C .actions-W .action .icon {
                height: 14px !important;
                margin: 0 8px 0 8px !important;
                font-weight: 700;
                font-size: 14px;
                line-height: 1;
                color: #6246EA;
            }


.changeMyPasswordModal {
}

    .changeMyPasswordModal .changeMyPasswordModal-header {
        background: #F8F8FB;
        padding: 80px 30px 15px 30px;
        text-align: center;
    }

    .changeMyPasswordModal .changeMyPasswordModal-header h2 {
        margin: 20px 0 0 0;
        font-size: 24px;
        line-height: 1.2;
        color: #595959;
    }

    .changeMyPasswordModal .changeMyPasswordModal-body {
        background: #F8F8FB;
        padding: 15px 90px 30px 90px;
    }

        .changeMyPasswordModal .changeMyPasswordModal-body .password-rules {
            margin: 4px 0 0 0;
        }

            .changeMyPasswordModal .changeMyPasswordModal-body .password-rules .rule {
                display: inline-block;
                width: 48%;
                margin: 0 0 4px 0;
                font-size: 12px;
            }

                .changeMyPasswordModal .changeMyPasswordModal-body .password-rules .rule .box {
                    display: inline-block;
                    background: #efefef;
                    border: 1px solid #D1D1E9;
                    width: 10px;
                    height: 10px;
                    margin: 0 4px 0 0;
                }

                .changeMyPasswordModal .changeMyPasswordModal-body .password-rules .rule.success .box {
                    background: #3f8e2b;
                }

                .changeMyPasswordModal .changeMyPasswordModal-body .password-rules .rule.danger .box {
                    background: #d5615d;
                }

    .changeMyPasswordModal-footer {
        background: #F8F8FB;
        padding: 15px 90px 90px 90px;
    }
.search-task-drawer-H {
    background: #F8F8FB;
    box-shadow: inset 0px -1px 0px #DFE0E1;
    padding: 20px 30px 20px 30px;
    font-size: 14px;
    line-height: 17px;
}

.search-task-drawer-B {
    padding: 16px 12px 16px 12px;
    font-size: 14px;
    line-height: 17px;
}

    .search-task-drawer-B .input-box {
        border: 1px solid #D1D1E9;
        border-radius: 10px;
    }

    .search-task-drawer-B .select-box .ant-select-selector {
        border: 1px solid #D1D1E9 !important;
        border-radius: 10px !important;
    }

    .helper-1 {
        margin: 40px 0 0 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 17px;
        color: #6246EA;        
    }

    .helper-2 {
        margin: 12px 0 0 0;
        font-size: 13px;
        line-height: 15px;
        color: #6C6D72;
    }